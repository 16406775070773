
@import "./react-image-gallery/css/image-gallery.css";




/* GENERAL ------------------------------------------------------------------------------------------*/

body {
  font-family: 'Open Sans', sans-serif;
  color: #404040;
}

p, li, a {
  font-size: 1.2rem;
}

h1, h2, h3 {
  color: #464e6d;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

h1 span,
h2 span,
h3 span {
  color: #858fb6;
  font-weight: 300;
}

h1:after,
h2:after,
h3:after {
  background-color: #c4574e;
  content: "";
  display: block;
  width: 6rem;
  height: .2rem;
  margin: 1rem auto 0 auto;
}

select:focus {
  outline-offset: 0;
  outline: none;
}

/* END GENERAL ------------------------------------------------------------------------------------------*/

/* HEADER ------------------------------------------------------------------------------------------*/

/* NAV ------------------------------------------------------------------------------------------*/

.header {
  padding: 2rem;
  display: inline-block;
}

.logo{
  margin: 0 auto;
}

.header .inscription_connexion {
  color: #C6645D;
  display: inline-block;
  font-weight: bold;
  margin: 0 0 2rem 0;
}

.header .inscription_connexion:hover {
  text-decoration: none;
  color: #474D6D;
}

.header .inscription_connexion.info_perso{
  display: block;
  color:#474D6D;
}

.header nav {
  position: relative;
  display: table;
  margin: 0 auto;
}

.header .nav{
  text-align: center;
}

.header nav .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 4rem;
}

.header nav .dropdown-toggle:hover {
  cursor: pointer;
}

.header nav .dropdown-toggle .icon-bar {
  border: .2rem solid #474D6D;
  border-radius: 25rem;
  width: 100%;
  margin: 0 0 .5rem;
  padding: 0;
}

.header nav .dropdown-toggle .icon-bar:last-child {
  margin: 0;
}

.header nav .dropdown {
  background: #fff;;
  display: none;
  left: 0;
  margin: 1rem auto 0 auto;
  min-width: 12em;
  padding: 0;
  position: absolute;
  top: 100%;
  z-index: 10;
  -webkit-box-shadow: rgba(0, 0, 0, 0.57) 2px 4px 17px -4px;
  box-shadow: rgba(0, 0, 0, 0.57) 2px 4px 17px -4px;
}

.header nav .dropdown ul {
  margin: 0;
  padding: 0;
}

.header nav .dropdown li {
  list-style-type: none;
  padding: 1rem;
  text-transform: uppercase;
  color: #464e6d;
}

.header nav .dropdown li.active {
  background-color: #464e6d;
  color: #fff;
}

.header nav .dropdown li a {
  color: #000;
  display: block;
  font-weight: 300;
  padding: .5em 1em;
  text-align: center;
  text-decoration: none;
}

.header nav .dropdown li.active a {
  color: #FFF;
  background: #000;
}

.header nav .dropdown li {
  border-bottom:.1rem solid #CCC;
}

.header nav .dropdown li:last-child {
  border-bottom:none;
}

/* END NAV ------------------------------------------------------------------------------------------*/

/* LOGO ------------------------------------------------------------------------------------------*/

.header .navLogo {
  margin-bottom: 3rem;
}

/* END LOGO ------------------------------------------------------------------------------------------*/

/* INFOS ------------------------------------------------------------------------------------------*/

.header ul {
  list-style-type: none;
  padding: 0;
}

.header ul.infosHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.header ul.infosHeader > li {
  padding: 1rem 0;
  color: #464e6d;
  margin: auto;
}

.header ul.infosHeader > li:not(:last-of-type) {
  border-bottom: .1rem solid #b7bccf;
  width: 50%;
}

.header ul.detailInfos li {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.header ul.detailInfos >li:first-of-type {
  color: #c4574e;
  font-weight: 600;
}

.inputCheckboxContent input{
  height: auto;
}


.formBloc2 .inputSecteur .inputVille{
  margin-bottom:2rem;
  margin-right: 4rem;
}

.image-alert{
  display:none;
}


/* END INFOS ------------------------------------------------------------------------------------------*/

.placeDispo {
  background:#C7625F;
  border-color:#C7625F;
  font-size:24px;
  color:#FFF;
  border-radius:.5rem;
  margin: 0 auto 2.5rem;
  display: table;
}

/* END HEADER ------------------------------------------------------------------------------------------*/


/* HOME ------------------------------------------------------------------------------------------*/

/* CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

.inscriptionStep1 label {
  font-size: 1.2rem;
}

.inscriptionStep1 .titleForm,
.inscriptionStep2 .titleForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inscriptionStep1 .titleForm p,
.inscriptionStep2 .titleForm p {
  margin: 0;
}


.inscriptionStep1 .titleForm i,
.inscriptionStep2 .titleForm i {
  font-size: 2rem;
}

.inscriptionStep2 .titleForm p:first-of-type {
  color: #d6d8e4;
}

.inscriptionStep2 .inputInfos,
.contact .inputInfos  {
  margin-bottom: 2rem;
}

#formContact .infos{
  margin-bottom: 2rem;
}

.inscriptionStep2.connexion .titleForm p:first-of-type {
  color: #fff;
}


/* END CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

/* SECTION BANNIERE ------------------------------------------------------------------------------------------*/

.banniere{
  background: #fcfcfc;
}

.banniere > img{
  display: none;
}

.banniere .contentBanniere a,
.homeBloc1 .annonce,
.homeBloc1 .annonce>a,
.placeMaisonBloc1 .contentPlaceMaison {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1rem 0;
}

.homeBloc1 .annonce>a{
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.banniere .contentBanniere .titreBanniere:first-of-type,
.placeMaisonBloc1 .contentPlaceMaison .titrePlaceMaison:first-of-type {
  margin-right: 1rem;
}

.banniere .contentBanniere .titreBanniere:last-of-type,
.homeBloc1 .annonce .contentAnnonce,
.placeMaisonBloc1 .contentPlaceMaison .titrePlaceMaison:last-of-type {
  margin-left: 1rem;
}

.homeBloc1 .annonce .contentAnnonce{
  width:90%;
}

.banniere .contentBanniere p,
.homeBloc1 .annonce p,
.placeMaisonBloc1 .contentPlaceMaison p  {
  color: #464e6d;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.banniere .contentBanniere p,
.placeMaisonBloc1 .contentPlaceMaison p{
  text-transform: uppercase;
}

.banniere .contentBanniere a:hover,
.placeMaisonBloc1 a:hover {
  text-decoration: none;
}

.banniere .contentBanniere a:hover p,
.placeMaisonBloc1 a:hover p {
  color: #c4574e;
}

.banniere .contentBanniere img,
.homeBloc1 .annonce img{
  width: 72%;
}

.banniere .accroche{
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
  color: #C6645D;
}

.banniere .form form{
  width: 100%;
  margin: 2rem auto;
}
.banniere .form form .villeInput {
  width:100%;
  display: inline-block;
}

.banniere .form form > input{
  width: 25%;
  margin: 0 auto;
  display: inline-block;
  /*margin-top: 1rem;*/
  background: #C6645D;
  color: white;
  border: none;
}
.banniere .form form .villeInput input{
  background-color: white;
}

/* END BANNIERE ------------------------------------------------------------------------------------------*/

/* SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/

.homeBloc3 > .container,
.homeBloc4 > .container,
.homeBloc5 > .container {
  border-bottom: solid .3rem #f1f4fa;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.homeBloc1 .annonce {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 2rem 0;
}

.homeBloc1 .annonce p:first-of-type,
.homeBloc1 .annonce p:last-of-type {
  margin-bottom: .5rem;
}

.homeBloc1 .annonce p:not(:first-of-type) {
  color: #858fb6;
  font-weight: 400;
}

.homeBloc1 .annonce a {
  color: #c4574e;
  font-weight: 600;
}

.homeBloc1 .annonce a:hover,
.homeBloc4 .lien a:hover {
  color: #464e6d;
  text-decoration: none;
  cursor: pointer;
}

.homeBloc1 .actions{
  width:100%;
}

.homeBloc1 .bouton{
  margin-bottom: 2rem;
}




/* END SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/


/* SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

.homeBloc2 p,
.demandeEtablissementBloc1 .container > p:first-child {
  text-align: justify;
  line-height: 2.4rem;
}
.homeBloc2 p,
.demandeEtablissementBloc1 .container > p {
  line-height: 2.4rem;
}
.homeBloc2 p.text-center{
  text-align: center;
}

.homeBloc2 img {
  margin: 1rem auto;
}

.bouton > a{
  display: table;
  text-align: center;
  margin: 2rem auto;
}

.bouton a,
.bouton > input,
.bouton button,
.contact .encadreForm .bouton > input {
  background-color: #c4574e;
  color: #fff;
  padding: 1rem;
  text-transform: uppercase;
  border: none;
  width: auto;
  border-radius: 0.5rem;
  display: table;
  margin: 0 auto;
}

.bouton a:hover,
.bouton > input:hover,
.contact .encadreForm .bouton > input:hover {
  background-color: #464e6d;
  text-decoration: none;
  cursor: pointer;
}

.resultatVideRecherche .bouton{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

/* END SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

/* SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

.homeBloc3 .container > p {
  color: #c4574e;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 600;
  margin: 2rem 0 0 0;
  text-align: center;
}

.homeBloc3 .contentFonctionnement {
  position: relative;
  background-color: #f1f4fa;
  padding: 5rem 2rem 2rem 2rem;
  margin: 10rem auto 0 auto;
}

.homeBloc3 .contentFonctionnement > img {
  width: 50%;
  position: absolute;
  top: -7rem;
  left: 0;
  right:0;
  margin: 0 auto;
}

.homeBloc3 .contentFonctionnement.fonctionnementDeux > img{
  width:60%;
}

.homeBloc3 .contentFonctionnement p {
  text-align: justify;
  line-height: 2.4rem;
  margin: 0;
}

.homeBloc3 .contentFonctionnement p:first-of-type,
.homeBloc3 .contentFonctionnement p:nth-of-type(3) {
  color: #464e6d;
  /* font-weight: 600;* */
  margin: 1.5rem 0 .5rem 0;
}


.homeBloc3 .contentFonctionnement .outils p{
  font-size:1.6rem;
  text-align: center;
}

.homeBloc3 .outils{
  padding: .5rem 0;
  min-height:50px;
  position: relative;
}

.outils img{
  margin:0 auto;
}

.homeBloc3 .contentFonctionnement .outils a{
  color:#464e6d;
}

/* END SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

/* SECTION HOMEBLOC4 ------------------------------------------------------------------------------------------*/

.homeBloc4 .container > div:not(:last-of-type) {
  margin: 2rem 0;
}

.homeBloc4 p,
.placeMaisonBloc1 p {
  line-height: 2.4rem;
  text-align: justify;
}

.homeBloc4 p.titreArticle,
.homeBloc4 .lien a {
  color: #c4574e;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.homeBloc4 img {
  margin: 1rem 0;
}

.homeBloc4 .lien {
  text-align: center;
}

/* END SECTION HOMEBLOC4 ------------------------------------------------------------------------------------------*/

/* SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

.homeBloc5 > .container {
  border: none;
}

.homeBloc5 .slider {
  position: relative;
}

.homeBloc5 .slider i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 2.4rem;
}

.homeBloc5 .slider i:first-of-type {
  left: 0;
}

.homeBloc5 .slider i:last-of-type {
  right: 0;
}

/* END SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

/* END HOME ------------------------------------------------------------------------------------------*/


/* PLACE MAISON ------------------------------------------------------------------------------------------*/

/* PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/

.placeMaisonBloc1,
.placesDispoBloc1,
.demandeEtablissementBloc1,
.visualisationDemandeBloc1,
.alerteDispoBloc1,
.infoDispoBloc1,
.inscriptionStep1,
.inscriptionStep2,
.contact,
.listeDemandeBloc1 {
  border-top: #b7bccf .1rem solid;
  display: inline-block;
  width: 100%;
  margin: 0 0 3rem 0;
}



.placeMaisonBloc1 a:hover {
  text-decoration: none;
}

.placeMaisonBloc1 a:hover p {
  color: #c4574e;
}

.placeMaisonBloc1 p span,
.demandeEtablissementBloc1 .formBloc2 .formBloc2_1 p.titleForm span, .urltitre {
  color: #464e6d;
  font-weight: 600;
  /*text-transform: uppercase;*/
}

.demandeEtablissementBloc1 .formBloc2 .formBloc2_1 p.titleForm span, .urltitre2 {
  color: #464e6d;
  font-weight: 600;
}

.placeMaisonBloc1 p span.rouge,
.demandeEtablissementBloc1 .formBloc2 .formBloc2_1 p.titleForm span.rouge, .urltitre span.rouge {
  color: #c4574e;
}

.placeMaisonBloc1 p span.rouge,
.demandeEtablissementBloc1 .formBloc2 .formBloc2_1 p.titleForm span.rouge, .urltitre2 span.rouge {
  color: #c4574e;
}


/* END PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/

/* END PLACE MAISON ------------------------------------------------------------------------------------------*/


/* PLACES DISPO ------------------------------------------------------------------------------------------*/

/* PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

p.chemin {
  margin: 1rem 0;
  color: #464e6d;
}

p.chemin a {
  color: #c4574e;
}

p.chemin a:hover {
  color: #464e6d;
  text-decoration: none;
}

.placesDispoBloc1 img,
.alerteDispoBloc1 img,
.infoDispoBloc1 img {
  width: 50%;
  margin: 2rem auto;
}

.encadreForm {
  border: solid .1rem #bfc8c8;
  padding: 2rem;
  margin: 2rem 0;
}

.encadreForm .inputConnexion {
  margin-bottom:30px;
}

.encadreForm .inputInfos{
  margin-bottom:30px;
  min-height: 10rem;
}

.encadreForm .inputInfos input,
.encadreForm .inputInfos select {
  min-height: 4rem;
}

.placesDispoBloc1 .formBloc1,
.placesDispoBloc1 .formBloc2,
.alerteDispoBloc1 .formBloc1,
.alerteDispoBloc1 .formBloc2,
.alerteDispoBloc1 .formBloc3,
.infoDispoBloc1 .formBloc1,
.infoDispoBloc1 .formBloc2,
.infoDispoBloc1 .formBloc3 {
  display: inline-block;
}

.placesDispoBloc1 .formBloc1 {
  margin-bottom: 2rem;
}

.question {
  display: inline-block;
  margin-bottom: 2rem;
  width: 100%;
}

.question p {
  color: #858fb6;
  margin-bottom: .5rem;
}

.question p:first-of-type {
  font-weight: 600;
  font-size: 1.4rem;
}

.inscriptionStep1 .inputCheckbox input[type=radio]{
  height:auto;
  margin-right:1rem;
}

.inscriptionStep1 .inputCheckbox{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-bottom:2rem;
}

.inscriptionStep1 .inputCheckboxContent > div,
.formBloc2 .inputCheckboxContent > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inscriptionStep1 .inputCheckboxContent > div > label,
.formBloc2 .inputCheckboxContent > div > label {
  margin: 0;
  padding: 0 0 0 1rem;
}

input, select, textarea {
  border: solid .1rem #bfc8c8;
  background-color: transparent;
  border-radius: initial;
}

input:focus,
textarea:focus {
  outline: none;
  border: solid .1rem #858fb6;
}

.inputCheckboxContent > input,
.inputCheckboxContent .row > input,
.inputCheckbox > input,
.inputCheckboxAbonnement > input,
.inputEtreContacte > input,
.btnRadio > input {
  width: auto;
}

.inputCheckboxAbonnement input{
  height: 1.9rem;
  width: 2.5rem;
}

.inputCheckboxContent > input,
.inputCheckboxContent .row > input,
.btnRadio > input {
  margin-right: 1rem;
}


input,
textarea,
select {
  height:4rem;
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: #404040;
  border-radius: .5rem;
}

input[type=radio] {
  height: auto;
}

.bouton.submit {
  margin-bottom: 0;
}

.inputCheckboxAbonnement label {
  font-weight: 300;
}

.inputCheckboxAbonnement strong{
  font-size:1.5rem;
}

/* END PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

/* END PLACES DISPO ------------------------------------------------------------------------------------------*/


/* DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

/* DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

.demandeEtablissementBloc1 .encadreForm{
  position:relative;
}

.cacher{
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  background: grey;
  opacity: 0.6;
}

.demandeEtablissementBloc1 .alert{
  z-index: 100;
  position: absolute;
  width: 90%;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.demandeEtablissementBloc1 .alert-danger {
  background: #474D6D;
  border: .1rem solid #858fb6;
  color: #FFF;
}

.demandeEtablissementBloc1 .alert-danger a {
  color: #FFF;
  display: inline-block;
  text-decoration: underline;
  width: 100%;
}

.demandeEtablissementBloc1 .formBloc1 {
  display: inline-block;
  border-bottom: .1rem solid #b7bccf;
}

.titleForm {
  color: #464e6d;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2rem
}

.formBloc1 .formBloc1_1,
.formBloc1 .formBloc1_2,
.formBloc1 .formBloc1_3,
.formBloc1 .formBloc1_4,
.formBloc1 .formBloc1_5,
.formBloc2 .formBloc2_1,
.formBloc2 .formBloc2_2,
.formBloc2 .formBloc2_3,
.formBloc2 .formBloc2_4,
.formBloc2 .formBloc2_5 {
  margin-bottom: 1rem;
}

.formBloc1 span,
.formBloc2 span,
.formBloc3 span,
label span {
  color: #c4574e;
  font-weight: 400;
}

.formBloc1 .formBloc1_1 input {
  margin: 0;
}

.formBloc2,
.formBloc3 {
  margin-top: 2rem;
}

textarea {
  height: 12rem;
}

.formBloc1_2 select{
  height: 4.5rem;
  margin: 0 2rem 0 0;
  width:10%;
}

.demandeEtablissementBloc1 .inputCheckboxAbonnement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.demandeEtablissementBloc1 .inputCheckboxAbonnement label {
  padding: 0 0 0 1rem;
  margin: 0;
}

/* END DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

/* VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

.visualisationDemandeBloc1 .titleForm,
.inscriptionStep1 .titleForm,
.inscriptionStep2 .titleForm {
  background-color: #b7bccf;
  color: white;
  margin: 0;
  padding: 1rem;
}

.titleForm .active{
  color: #fff;
}

.visualisationDemandeBloc1 .titleForm span {
  text-transform: uppercase;
}

.visualisationDemandeBloc1 .encadreForm,
.inscriptionStep1 .encadreForm,
.inscriptionStep2 .encadreForm {
  display: table;
  margin: 0;
}


.visualisationDemandeBloc1 .encadreForm:last-of-type,
.inscriptionStep1 .encadreForm:last-of-type,
.inscriptionStep2 .encadreForm:last-of-type {
  margin-bottom: 2rem;
}

.visualisationDemandeBloc1 .titleLieu {
  color: #858fb6;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.listeDemandeBloc1 .visualisationInfo,
.visualisationDemandeBloc1 .visualisationInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 1rem 0;
}

.visualisationInfo i,
.subTitleResultat i{
  font-size:2rem;
  color:#c4574e;
}

.blocAlerteDispo .visualisationInfo i, #listeDemande i{
  font-size: 3rem;
}

.subTitleResultat i{
  display: inline-block;
  margin-right: 1.5rem;
}

.listeDemandeBloc1 .visualisationInfo p,
.listeDemandeBloc1 .visualisationInfo li,
.visualisationDemandeBloc1 .visualisationInfo p,
.visualisationDemandeBloc1 .visualisationInfo li {
  margin: 0 0 0 1rem;
}

.listeDemandeBloc1 .visualisationInfo ul,
.visualisationDemandeBloc1 .visualisationInfo ul {
  list-style-type: none;
  padding: 0;
}

.visualisationDemandeBloc1 .boutons .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.visualisationDemandeBloc1 .boutons .bouton {
  margin: 1rem 0;
}

.visualisationDemandeBloc1 .boutons .bouton.modifier a, .visualisationDemandeBloc1 .boutons .bouton.modifier button {
  margin-right: 1rem;
  background-color: #b7bccf;
}

.visualisationDemandeBloc1 .boutons .bouton.modifier a:hover {
  background-color: #c4574e;
}

/* END VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

/* END DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/


/* LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

.listeDemandeBloc1 .filtres {
  margin: 2rem 0;
}

.filtres input{
  margin-bottom:2rem;
}

.listeDemandeBloc1 .filtres > p {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

.listeDemandeBloc1 select {
  width: 100%;
  height: 4rem;
  margin-bottom: 2rem;
}

.listeDemandeBloc1 .bouton {
  margin: 0 auto;
}

.listeDemandeBloc1 .singleDemande {
  display: inline-block;
  border: #bec8c9 .2rem solid;
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 100%;
}

.listeDemandeBloc1 .singleDemande .imagesAnnonces{
  width:100%;
  height:auto;
  margin-bottom: 2.5rem;
}

.listeDemandeBloc1 .singleDemande .imagesAnnonces .slick-list.draggable{
  width:100%;
}

.listeDemandeBloc1 .singleDemande .imagesAnnonces img{
  height:20rem;
}

.slick-prev, .slick-next{
  top:65%;
  z-index: 30;
}

.slick-prev:before, .slick-next:before{
  color:white;
}

.slick-next{
  right:1rem;
}

.slick-prev{
  left:1rem;
}

.listeDemandeBloc1 .singleDemande .question p {
  text-transform: uppercase;
  text-align: center;
}

.listeDemandeBloc1 .singleDemande .question {
  margin-bottom: 0;
}

.listeDemandeBloc1 .singleDemande .bouton.voir{
  text-align: left;
  margin: 2rem 0 2rem 2rem;
  display: inline-block;
}

.listeDemandeBloc1 .singleDemande .bouton.voir a,
.listeDemandeBloc1 .singleDemande .bouton.supprimer a{
  padding: 1rem 1rem;
}

.listeDemandeBloc1 .singleDemande .bouton.supprimer{
  text-align: left;
  margin: 2rem 0 1rem 2rem;
  display: inline-block;
}

/* END LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/


/* ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/

.alerteDispoBloc1 .inputInfo,
.infoDispoBloc1 .inputInfo {
  margin-bottom: 2rem;
}

.alerteDispoBloc1 .bouton,
.infoDispoBloc1 .bouton {
  margin: 0 auto;
}

.alerteDispoBloc1 .inputCheckboxAbonnement label,
.infoDispoBloc1 .inputCheckboxAbonnement label {
  font-weight: 400;
  font-size: 1.2rem;
}

alerteDispoBloc1 strong{
  font-size:1.5rem;
}

/* END ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/


/* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

.resultatRecherche .sidebar {
  -webkit-box-shadow: .5rem 0 .5rem -.2rem rgba(0,0,0,0.17);
  box-shadow: .5rem 0 .5rem -.2rem rgba(0,0,0,0.17);
  z-index: 1;
}


.resultatRecherche .map img {
  width: 100%;
}

.resultatRecherche .titleResultat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #f1f4fa;
  padding: .5rem 1rem;
}

.resultatRecherche .titleResultat p {
  color: #464e6d;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.resultatRecherche .titleResultat p span {
  color: #858fb6;
  font-weight: 400;
}

.resultatRecherche .titleResultat a {
  color: #c4574e;
  font-weight: 400;
  text-transform: none;
}

.singleResultat,
.boutons {
  padding: 1rem;
}

.singleResultat label {
  font-weight: 400;
  color: #c4574e;
  margin-left: 1rem;
  font-size: 1.2rem;
}

.singleResultat:not(:last-of-type) {
  border-bottom: solid .1rem #b7bccf;
  padding-bottom: 2rem;
}

.singleResultat img {

}

.singleResultat > div {
  margin-bottom: 1rem;
}

.singleResultat > .iconeResultat {
  margin-right: 1rem;
}

.singleResultat .iconeResultat img:first-of-type {
  width: 30%;
}

.singleResultat .iconeResultat img:last-of-type {
  width: 5%;
  margin-top:.5rem;
  float:right;

}

.singleResultat div.inputEtreContacte {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.singleResultat p {
  text-align: justify;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom:1.5rem;
}

.singleResultat .titleSingleResultat {
  color: #464e6d;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

.singleResultat .publishedStyle {
  color: #464e6d;
}

.singleResultat .subTitleResultat {
  color: #858fb6;
  text-transform: uppercase;
  text-align: left;
}

.singleResultat .subTitleResultat.mail, .singleResultat .subTitleResultat.website{
  text-transform: lowercase;
}

.singleResultat .publish_date{
  margin: 2rem 0;
  font-size:1rem!important;
}

.infosResultat .description{
  text-transform: lowercase;
}

.subTitleResultat a{
  font-size: 1.2rem;
}

.sidebar .bouton {
  margin: 1rem auto 3rem auto;
}

.sidebar .boutons .bouton.imprimer a {
  background-color: #b7bccf;
}

.modal h4 {
  color: #464e6d;
  text-align: center;
  text-transform: uppercase;
}

.modal .inputInfo {
  margin: 2rem 0;
}

.modal .inputInfo input {
  width: 100%;
}

.modal .inputInfo span {
  color: #c4574e;
}

.modal .inputCheckboxAbonnement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal .inputCheckboxAbonnement label {
  margin-left: .5rem;
}

.modal .inputCheckboxAbonnement label {
  font-weight: 400;
  font-size: 1.2rem;
}

.modal .bouton.submit {
  text-align: center;
}

.lienForm {
  background-color: #464e6d;
  padding: 2rem 2rem 1rem 2rem;
}

.lienForm.infoDispo {
  background-color: #858fb6;
}

.lienForm.deposerDemande {
  background-color: transparent;
}

.lienForm .singleLienForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.lienForm .singleLienForm img {
  width: 20%;
  margin-right: 2rem;
}

.lienForm .singleLienForm .contentLienForm {
  color: #fff;
}

.lienForm.deposerDemande .singleLienForm .contentLienForm p:last-of-type {
  color: #464e6d;
  font-weight: 600;
}

.lienForm .singleLienForm .contentLienForm p:first-of-type {
  text-transform: uppercase;
  font-weight: 600;
}

.lienForm .singleLienForm .contentLienForm p:last-of-type {
  font-weight: 300;
}

.lienForm > .bouton {
  margin: 1rem auto 2rem auto;
}

.lienForm.deposerDemande .bouton a {
  background-color: #d7dae8;
  color: #858fb6;
}

.lienForm.deposerDemande .bouton a:hover {
  background-color: #858fb6;
  color: #fff;
}

/* END RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/


/* CONTACT ------------------------------------------------------------------------------------------*/

.contact .encadreForm {
  border: none;
}

.contact .encadreForm input {
  width: 100%;
}

.contact .encadreForm .bouton {
  text-align: center;
}

/* END CONTACT ------------------------------------------------------------------------------------------*/


/* MON COMPTE ------------------------------------------------------------------------------------------*/

.buttonMonCompte {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 3rem 0 0 0;
}

.buttonMonCompte > .bouton.submit {
  margin: 0 0 4rem 0;
}

.buttonMonCompte > .bouton.submit > a {
  display: table;
  margin: 0 auto 1rem;
}

.buttonMonCompte > .bouton.submit > p {
  text-align: center;
}

/* FOOTER ------------------------------------------------------------------------------------------*/

footer {
  display: inline-block;
  width: 100%;
}

.footerBloc1 {
  background-color: #464e6d;
  padding: 2rem 0;
  margin: 0;
}

.footerBloc1 > p {
  text-align: center;
  margin: 3rem 0 1rem 0;
}

.footerBloc1 a:hover {
  color: #c4574e;
  text-decoration: none;
}

.footerBloc1 p,
.footerBloc1 p a {
  color: #fff;
  font-weight: 300;
}

.footerBloc1 .footerBloc1_1 p,
.footerBloc1 .footerBloc1_1 p a {
  text-transform: uppercase;
}

.footerBloc1 .footerBloc1_1 {
  display: inline-block;
  margin: 1rem 0;
}

.footerBloc1 .footerBloc1_1 > div:first-of-type p {
  font-weight: 600;
}

.footerBloc1 .footerBloc1_2 img {
  margin: 1rem auto;
}

.footerBloc1 .footerBloc1_2 p {
  text-align: center;
}

.footerBloc1 .footerBloc1_2 p a {
  font-weight: 600;
}

.singleKm {
  border: 1px solid black;
  border-radius: 50px;
  width: 5rem;
  height: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1rem;
}

.singleKm.active {
  background: #c4574e;
  color: white;
  border-color: #c4574e;
}

.singleKm:focus {outline:0;}

.infoSpecial > div{
  padding: 2rem 2rem 0 2rem;
}

.infoSpecial .listeEtablissementTri{

  width: 100%;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin:0 auto;
}

.infoSpecial .listeEtablissementTri img{
  margin:0 auto;
}

.singleEtablissementTri {
  display: inline-block;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
  width: 40%;
}

.infoSpecial .listeKm{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 639px)
{
  .triVille .list_result{
    top:100%!important;
    left:1rem;
  }

}

/* END FOOTER ------------------------------------------------------------------------------------------*/



.singleImageUpdate {
  min-height: 22rem;
}

.singleImageUpdate .crossDeleteImage{
  position: absolute;
  font-size: 3rem;
  color: #c76275;
  top: -20px;
  left: 4px;
  cursor: pointer;
}

.singleImageUpdate input{
  margin-top:2rem;
}

@media screen and (min-width : 768px) {

  p, li, a {
    font-size: 1.4rem;
  }

  h1, h2, h3 {
    font-size: 2.4rem;
    margin: 3rem 0 2rem 0;
  }

  .demandeEtablissementBloc1 .titleForm{
    font-size: 2.5rem;
  }

  /* HEADER ------------------------------------------------------------------------------------------*/

  header .infos {

  }

  .placeDispo {
    font-size: 2rem;
    position: absolute;
    right: 2rem;
    top: 5rem;
    width: auto;
    margin: 0;
  }

  /* LOGO ------------------------------------------------------------------------------------------*/


  .header .navLogo {
    margin-bottom: 0;
  }

  .header .nav{
    text-align: left;
  }

  .header nav .dropdown{
    width: 25rem;
    min-width: inherit;
  }

  .header nav{
    margin: 0;
  }

  .banniere > img{
    display: block;
  }

  .banniere .accroche{
    font-size: 2.5rem;
    margin: 0;
    text-align: center;
    color: white;
  }

  .banniere .form form {
    width: 50rem;
    margin: 1rem auto;
  }

  .banniere .form form .villeInput{
    width:80%
  }

  .banniere .form form > input{
    width:19%;
    margin:0;
    margin-left: 3px;
    display: inline;
  }
  /* END LOGO ------------------------------------------------------------------------------------------*/

  /* INFOS ------------------------------------------------------------------------------------------*/

  .header ul.infosHeader {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .header ul.infosHeader > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .header ul.infosHeader > li:not(:last-of-type) {
    border-bottom: none;
    width: auto;
  }

  .header ul.infosHeader > li:not(:last-of-type):after {
    content: '';
    border-right: .1rem solid #b7bccf;
    padding-right: 5rem;
  }


  .contentFonctionnement .bouton{
    position:absolute;
    left:0;
    right:0;
    bottom:2rem;
  }

  /* INFOS ------------------------------------------------------------------------------------------*/

  /* END HEADER ------------------------------------------------------------------------------------------*/

  .infoSpecial .listeEtablissementTri{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }

  .infoSpecial .secondFiltre{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }

  .infoSpecial .listeKm{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width:70%;
  }

  .singleKm {
    margin-top:-2rem;
  }

  /* HOME ------------------------------------------------------------------------------------------*/

  /* CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  .inscriptionStep1,
  .inscriptionStep2 {
    margin-bottom: 2rem;
  }

  .inscriptionStep2 .inputConnexion {
    margin-bottom: 2rem;
  }

  .inscriptionStep1 .inputCheckbox {
    margin: 2rem 0 4rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .inscriptionStep1 .inputCheckbox input {
    margin-right: .5rem;
  }

  .inscriptionStep1 .titleForm i,
  .inscriptionStep2 .titleForm i {
    font-size: 2.6rem;
  }

  .inscriptionStep2 .encadreForm input,
  .contact .encadreForm input {
    width: 100%;
  }

  .inscriptionStep2 .encadreForm .bouton > input {
    width: auto;
  }

  .inscriptionStep2 .encadreForm .bouton {
    text-align: center;
  }

  .inscriptionStep2 .encadreForm .inputEntite {
    margin-bottom: 2rem;
  }

  .inscriptionStep1 .inputInfos{
    margin-bottom: 0;
  }

  /* END CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  /* SECTION BANNIERE ------------------------------------------------------------------------------------------*/

  .banniere {
    position: relative;
    display: inline-block;
  }

  .banniere > .container {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }

  .banniere .contentBanniere .titreBanniere:first-of-type,
  .placeMaisonBloc1 .contentPlaceMaison .titrePlaceMaison:first-of-type {
    margin-right: 2rem;
  }

  .banniere .contentBanniere .titreBanniere:last-of-type,
  .homeBloc1 .annonce .contentAnnonce,
  .placeMaisonBloc1 .contentPlaceMaison .titrePlaceMaison:last-of-type {
    margin-left: 2rem;
  }

  .banniere .contentBanniere img{
    width: 25%;
  }
  .placeMaisonBloc1 .contentPlaceMaison{
    margin:1rem;
  }

  .banniere .contentBanniere p {
    color: #fff;
  }

  .image-alert{
    display: block;
  }

  /* END BANNIERE ------------------------------------------------------------------------------------------*/


  /* SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/

  .homeBloc1 .annonce {
    min-height: 13rem;
  }

  .homeBloc1 .annonce p,
  .homeBloc1 .annonce a {
    font-size: 1.4rem;
  }

  .homeBloc1 .annonce img {
    width: 31%;
    border-radius: 50%;
  }

  .homeBloc1 .actions{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .homeBloc1 .bouton{
    margin-bottom: 0;
  }

  .homeBloc1 .annonce .contentAnnonce{
    width:70%;
  }

  /* END SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/


  /* SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

  .bouton {
    margin: 3rem auto 2rem auto;
  }

  .infoDispo .bouton{
    position:absolute;
    margin:0 auto;
    bottom:4rem;
  }

  .homeBloc2{
    margin-bottom:5rem;
  }

  .homeBloc2 img {
    margin: auto;
  }



  /* END SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC4 ------------------------------------------------------------------------------------------*/

  .homeBloc4 .container > div:not(:last-of-type) {
    margin: 0;
  }

  .homeBloc4 p.titreArticle,
  .homeBloc4 .lien a {
    font-size: 1.6rem;
  }

  .homeBloc4 img {
    margin: 2rem 0;
  }

  /* END SECTION HOMEBLOC4 ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

  .homeBloc5 .slider i {
    font-size: 3rem;
  }

  .homeBloc5 .slider i:first-of-type {
    left: -1.5rem;
  }

  .homeBloc5 .slider i:last-of-type {
    right: -1.5rem;
  }

  .homeBloc3 .contentFonctionnement > img{
    width:40%;
  }

  .homeBloc3 .contentFonctionnement.fonctionnementDeux > img{
    width:50%
  }



  /* END SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/


  /* END HOME ------------------------------------------------------------------------------------------*/


  /* PLACE MAISON ------------------------------------------------------------------------------------------*/

  /* PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/


  .placeMaisonBloc1 .contentPlaceMaison:first-of-type p {
    text-align: right;
  }

  .placeMaisonBloc1 .contentPlaceMaison:first-child{
    padding-right: 5rem;
    padding-right: 0rem;
    width: 50%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .placeMaisonBloc1 .contentPlaceMaison:last-child{
    padding-left: 0rem;
    width: 50%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .placeMaisonBloc1 .contentPlaceMaisonText{
    padding-right:8rem;
  }

  .placeMaisonBloc1 .lienImg{
    width:15%;
  }

  .placeMaisonBloc1 .lienImg:first-child{
    margin-left: 7rem;
  }

  .placeMaisonBloc1 .lienImg:last-child{
    margin-right: 8rem;
  }

  .placeMaisonBloc1 .contentPlaceMaisonText:last-child{
    padding-right: inherit;
    padding-left: 8rem;
  }

  .placeMaisonBloc1 .placeMaisonIcone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 2rem 0;
  }

  /* END PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END PLACE MAISON ------------------------------------------------------------------------------------------*/

  /* PLACES DISPO ------------------------------------------------------------------------------------------*/

  /* PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  .formBloc1 .formBloc1_1,
  .formBloc1 .formBloc1_2,
  .formBloc1 .formBloc1_3,
  .formBloc1 .formBloc1_4,
  .formBloc1 .formBloc1_5,
  .formBloc2 .formBloc2_1,
  .formBloc2 .formBloc2_2,
  .formBloc2 .formBloc2_3,
  .formBloc2 .formBloc2_4,
  .formBloc2 .formBloc2_5,
  .formBloc2 .formBloc2_6,
  .formBloc2 .formBloc2_7 {
    /*margin-bottom: 3rem;*/
  }

  p.chemin {
    margin: 2rem 0;
  }

  .placesDispoBloc1 img,
  .alerteDispoBloc1 img,
  .infoDispoBloc1 img {
    width: 100%;
  }

  .question p:first-of-type {
    font-size: 1.6rem;
  }

  input {
    margin-bottom: 0;
  }

  .inputSecteur {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  form[name=appbundle_alertedispo] .formBloc3 input[type=text], form[name=appbundle_infodispo] .formBloc3 input[type=text]{
    width:100%
  }

  .inputSecteur .inputVille {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
  }

  .inputVille > input {
    width: 22rem;
    margin-right: 2rem;
  }

  .selectRayon select,
  .inputAge > input {
    padding: 1rem;
    margin: 0 1rem;
    width: 35%;
  }

  .bouton.submit {
    text-align: right;
  }


  .inscriptionStep1 .inputCheckboxContent > div {
    margin: 0 -15px 15px -15px;
  }
  /* END PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END PLACES DISPO ------------------------------------------------------------------------------------------*/


  /* DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  /* DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  .demandeEtablissementBloc1 .alert-danger a {
    display: inline-block;
    width: auto;
  }

  .formBloc1 .formBloc1_1 .inputDemande > input {
    width: 30rem;
  }

  .formBloc1 .formBloc1_4 .inputEcheance,
  .formBloc1 .formBloc1_5 .inputQui,
  .formBloc2 .formBloc2_1 .inputLieu,
  .formBloc2 .formBloc2_4 .inputResidence,
  .formBloc2 .formBloc2_5 .inputCivilite {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .formBloc1 .formBloc1_4 .inputEcheance{
    margin-top:1rem;
  }

  .formBloc1 .formBloc1_5 .row > div:nth-of-type(2) {
    margin-bottom: 3rem;
  }

  .formBloc1 .formBloc1_4 .inputEcheance .btnRadio,
  .formBloc1 .formBloc1_5 .inputQui .btnRadio,
  .formBloc2 .formBloc2_1 .inputLieu .btnRadio,
  .formBloc2 .formBloc2_4 .inputResidence .btnRadio,
  .formBloc2 .formBloc2_5 .inputCivilite .btnRadio {
    margin-right: 4rem;
  }

  .inputAge > input {
    width: 5rem;
  }

  .formBloc2 .formBloc2_6 input {
    width: 100%;
  }

  .formBloc2 .formBloc2_6 .infos:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  /* END DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  /* VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  .visualisationDemandeBloc1 .titleLieu {
    font-size: 1.6rem;
  }

  .visualisationDemandeBloc1 .boutons .bouton.modifier a, .visualisationDemandeBloc1 .boutons .bouton.modifier button {
    margin-right: 2rem;
  }

  /* END VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  /* END DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/


  /* LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

  .listeDemandeBloc1 input {
    margin-bottom: 2rem;
  }

  .listeDemandeBloc1 .singleDemande {
    min-height: 46rem;
    position: relative;
  }


  #listeDemande{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .listeDemandeBloc1 .singleDemande .bouton.voir {

  }

  .listeDemandeBloc1 .singleDemande .bouton.supprimer {

  }


  /* END LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

  .encadreForm .inputInfo{
    margin-bottom: 0;
  }

  /* ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/

  .alerteDispoBloc1 .inputCheckboxAbonnement,
  .infoDispoBloc1 .inputCheckboxAbonnement {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .alerteDispoBloc1 .inputCheckboxAbonnement label,
  .infoDispoBloc1 .inputCheckboxAbonnement label {
    margin-left: .5rem;
  }

  /* END ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/


  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

  .resultatRecherche .sidebar {
    overflow: scroll;
    height: 100vh;
  }

  .resultatRecherche .titleResultat p,
  .resultatRecherche .singleResultat p {
    font-size: 1.2rem;
  }

  .sidebar .bouton a {
    font-size: 1.2rem;
  }

  .lienForm .singleLienForm {
    margin-bottom: 2rem;
  }

  .lienForm .singleLienForm .contentLienForm p {
    font-size: 1.2rem;
  }

  .lienForm > .bouton {
    margin: 1rem auto 3rem auto;
  }

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/
  .homeBlockdispo > div{
    min-height: 40rem;
    position:relative;

  }

  /* FOOTER ------------------------------------------------------------------------------------------*/

  .footerBloc1 p,
  .footerBloc1 p a {
    font-size: 1.2rem;
  }

  /* END FOOTER ------------------------------------------------------------------------------------------*/

  .singleResultat .iconeResultat img:last-of-type{
    width:10%;
  }

  .outils{
    margin-bottom:3rem;
  }

  .placeMaisonBloc1 .contentPlaceMaison {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .contentFonctionnement{
    min-height: 90rem;
  }

}


@media screen and (min-width : 992px) {

  /* HOME ------------------------------------------------------------------------------------------*/

  /* CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  .header{
    width:100%;
  }

  .header ul.infosHeader{
    margin-top: 2rem;
  }

  .banniere .form form {
    width: 50rem;
    margin: 2rem auto;
  }

  .banniere .form form input{
    height: 6rem;
    font-size:2rem;
  }



  .banniere .accroche{
    font-size:3.5rem;
  }

  .banniere .form form{
    margin: 3rem auto;
  }

  .homeBlockdispo > div{
    min-height: 36rem;
  }

  .homeBloc3 .contentFonctionnement .outils a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .homeBloc3 .contentFonctionnement .outils p{
    text-align: left;
    margin: 0;
  }

  .homeBloc3 .contentFonctionnement > img{
    width:35%
  }

  .homeBloc3 .contentFonctionnement.fonctionnementDeux > img{
    width:45%
  }

  .contentFonctionnement .outils{
    margin: 0;
  }

  .inscriptionStep2 .inputConnexion {
    margin-bottom: 4rem;
  }

  .inscriptionStep2 .encadreForm .bouton {
    margin-bottom: 2rem;
  }

  .inscriptionStep2 .encadreForm > .question {
    margin: 2rem 0 4rem 0;
  }

  .inscriptionStep2 .encadreForm .inputEntite,
  .inscriptionStep2 .encadreForm .inputInfos {
    margin-bottom: 4rem;
  }

  /* END CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  /* SECTION BANNIERE ------------------------------------------------------------------------------------------*/

  .banniere .contentBanniere img {
    width: 20%;
  }

  .banniere .contentBanniere p {
    font-size: 1.6rem;
  }

  .banniere .contentBanniere p:first-of-type {
    margin-bottom: .5rem;
  }

  /* END BANNIERE ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

  .homeBloc3 .container > p {
    font-size: 1.8rem;
  }

  .homeBloc3 .contentFonctionnement {
    padding: 8rem 3rem 2rem 3rem;
    margin: 13rem auto 0 auto;
    min-height: 75rem;
  }

  /* END SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

  /* END HOME ------------------------------------------------------------------------------------------*/


  /* PLACES DISPO ------------------------------------------------------------------------------------------*/

  /* PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  .placesDispoBloc1 .inputCheckboxContent,
  .alerteDispoBloc1 .inputCheckboxContent {
    min-height: 10rem;
  }

  .inputCheckboxContent:not(:last-of-type) {
    padding-right: 2rem;
  }


  /* END PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END PLACES DISPO ------------------------------------------------------------------------------------------*/


  /* DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  /* DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  .inputAge > input {
    width: 7rem;
  }

  .formBloc2 .formBloc2_2 .inputBudget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .formBloc2 .formBloc2_2 .inputBudget .btnRadio {
    margin-right: 2rem;
  }

  /* END DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/


  /* LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

  .listeDemandeBloc1 .filtres {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 3rem 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .listeDemandeBloc1 .filtres > p {
    width: 40%;
    margin-bottom: 0;
  }

  .listeDemandeBloc1 select,
  .listeDemandeBloc1 input {
    /*width: 90%;*/
    margin: 0 1rem;
    height: 4rem;
  }



  .listeDemandeBloc1 .singleDemande > div:first-of-type {
    margin-bottom: 1rem;
  }

  .listeDemandeBloc1 .singleDemande .bouton.voir {
    margin: 3rem 0 1rem 2rem;
  }

  /* END LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/


  /* ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/

  .formBloc2, .formBloc3 {
    margin-top: 4rem;
  }

  .alerteDispoBloc1 .inputInfo,
  .infoDispoBloc1 .inputInfo {
    margin-bottom: 4rem;
  }

  /* END ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/


  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

  .singleResultat .iconeResultat img:first-of-type {
    width: 15%;
  }

  .singleResultat .iconeResultat img:last-of-type {
    max-width: 5%;
  }

  /* END RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/


  /* CONTACT ------------------------------------------------------------------------------------------*/

  .contact .encadreForm {
    width: 70%;
    margin: 2rem auto;
  }

  /* END CONTACT ------------------------------------------------------------------------------------------*/


  /* MON COMPTE ------------------------------------------------------------------------------------------*/

  .buttonMonCompte {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .buttonMonCompte > .bouton.submit {
    margin: 0 auto 5rem;
    width: 75%;
    border: .1rem solid #CCC;
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .buttonMonCompte > .bouton.submit > p {
    width: 60%;
    text-align: left;
  }

  .buttonMonCompte > .bouton.submit > a {
    margin: 0;
    text-align: center;
    width: 30%;
  }

  /* FOOTER ------------------------------------------------------------------------------------------*/

  .footerBloc1 > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .footerBloc1 .footerBloc1_2 img {
    margin: 1rem auto 3rem auto;
  }

  /* END FOOTER ------------------------------------------------------------------------------------------*/

  .filtres .villeInput{
    width:90%;
  }



}

@media screen and (min-width : 1024px) {

  .infoSpecial .listeEtablissementTri{
    width: 70%;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;

  }

  .infoSpecial .secondFiltre {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 29%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
  }

  .infoSpecial .listeKm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
  }

  .singleKm {
    margin-top: 2rem;
  }

  .infoSpecial{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .listeDemandeBloc1 input[type=radio]{
    width:2rem;
    height:2rem;
  }

  .listeDemandeBloc1 input[type=submit]{
    margin:2rem auto 0;
    width:100%;
  }

}

@media screen and (min-width : 1200px) {

  h1, h2, h3 {
    font-size: 2.6rem;
    margin: 3rem 0 3rem 0;
  }

  /* HEADER ------------------------------------------------------------------------------------------*/

  /* LOGO ------------------------------------------------------------------------------------------*/

  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 2rem;
  }

  .header img {
    margin: auto;
  }

  /* END LOGO ------------------------------------------------------------------------------------------*/

  /* INFOS ------------------------------------------------------------------------------------------*/

  .header ul.infosHeader > li:not(:last-of-type):after {
    padding-right: 1rem;
  }

  .header ul.infosHeader > li:not(:first-of-type) {
    margin-left: 1rem;
  }

  /* END INFOS ------------------------------------------------------------------------------------------*/

  /* END HEADER ------------------------------------------------------------------------------------------*/


  /* HOME ------------------------------------------------------------------------------------------*/

  /* CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  .inscriptionStep1 .titleForm p,
  .inscriptionStep2 .titleForm p {
    font-size: 1.8rem;
  }

  .inscriptionStep1 .titleForm i,
  .inscriptionStep2 .titleForm i {
    font-size: 3rem;
  }

  .inscriptionStep1 label {
    font-size: 1.4rem;
  }

  .inscriptionStep1 {
    margin-bottom: 4rem;
  }

  .inscriptionStep2 .encadreForm .inputEntite label {
    margin-right: 1rem;
  }

  .inscriptionStep2 .encadreForm .inputInfos input {
    width: 100%;
  }

  /* END CONNEXION INSCRIPTION ------------------------------------------------------------------------------------------*/

  /* SECTION BANNIERE ------------------------------------------------------------------------------------------*/

  .banniere .contentBanniere img {
    width: 16%;
  }

  .banniere .contentBanniere p {
    font-size: 1.8rem;
  }

  /* END BANNIERE ------------------------------------------------------------------------------------------*/


  /* SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/

  .homeBloc1 > .container,
  .homeBloc2 > .container,
  .homeBloc3 > .container,
  .homeBloc4 > .container,
  .homeBloc5 > .container {
    padding-bottom: 4rem;
    margin-bottom: 1rem;
  }

  /* END SECTION HOMEBLOC1 ------------------------------------------------------------------------------------------*/


  /* SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

  .bouton {
    margin: 4rem auto 1rem auto;
  }

  .bouton button,
  .bouton > input {
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
  }

  /* END SECTION HOMEBLOC2 ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

  .homeBloc3 .container > p {
    font-size: 2rem;
  }

  .homeBloc3 img {
    width: 45%;
  }

  .homeBloc3 .outils img{
    width:20%;
    margin:0;
  }

  .homeBloc3 .contentFonctionnement {
    padding: 8rem 3rem 4rem 3rem;
    min-height: 60rem;
  }

  .homeBloc3 .contentFonctionnement > img {
    width: 30%;
  }

  .homeBloc3 .contentFonctionnement.fonctionnementDeux > img {
    width: 37%;
  }



  /* END SECTION HOMEBLOC3 ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

  .homeBloc5 .slider i {
    font-size: 5rem;
  }

  .homeBloc5 .slider i:first-of-type {
    left: -2.5rem;
  }

  .homeBloc5 .slider i:last-of-type {
    right: -2.5rem;
  }

  /* END SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

  /* END HOME ------------------------------------------------------------------------------------------*/


  /* PLACE MAISON ------------------------------------------------------------------------------------------*/

  /* PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/

  .placeMaisonBloc1 .placeMaisonIcone {
    margin-bottom:4rem;
    margin-top: -.5rem;
  }

  /* END PLACE MAISON SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END PLACE MAISON ------------------------------------------------------------------------------------------*/

  /* PLACES DISPO ------------------------------------------------------------------------------------------*/

  /* PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  .placesDispoBloc1 img,
  .alerteDispoBloc1 img {
    width: 85%;
  }

  .placesDispoBloc1 .inputCheckboxContent,
  .alerteDispoBloc1 .inputCheckboxContent,
  .infoDispoBloc1 .inputCheckboxContent {
    min-height: 0;
  }

  .inputCheckboxContent:not(:last-of-type) {
    padding-right: 3rem;
  }

  .encadreForm {
    padding: 3rem;
  }

  .inputCheckboxContent label {
    font-size: 1.2rem;
  }

  .inputCheckboxContent p {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .formBloc2 .formBloc2_2 .inputBudget .btnRadio {
    margin-right: 4rem;
  }

  /* END PLACES DISPO SECTION 1 ------------------------------------------------------------------------------------------*/

  /* END PLACES DISPO ------------------------------------------------------------------------------------------*/


  /* DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  /* DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  .formBloc1 .formBloc1_1,
  .formBloc1 .formBloc1_2,
  .formBloc1 .formBloc1_3,
  .formBloc1 .formBloc1_4,
  .formBloc1 .formBloc1_5,
  .formBloc2 .formBloc2_1,
  .formBloc2 .formBloc2_2,
  .formBloc2 .formBloc2_3,
  .formBloc2 .formBloc2_4,
  .formBloc2 .formBloc2_5,
  .formBloc2 .formBloc2_6,
  .formBloc2 .formBloc2_7 {
    margin-bottom: 1rem;
  }

  .titleForm {
    margin-bottom: 1rem
  }

  .formBloc1 .formBloc1_2 .inputCheckboxContent p,
  .formBloc1 .formBloc1_2 .inputCheckboxContent label,
  .formBloc2 .formBloc2_3 .inputCheckboxContent label {
    font-size: 1.4rem;
  }

  .formBloc1 .formBloc1_2 .inputCheckboxContent:not(:last-of-type) {
    padding-right: 6rem;
  }

  .formBloc2 .formBloc2_6 .inputInfos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .formBloc2 .formBloc2_6 .inputInfos .infos {
    margin-bottom: 0;
    width: 25%;
  }

  .formBloc2 .formBloc2_6 .inputInfos[placeholder] {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  ::-moz-placeholder { text-overflow: ellipsis; } /* firefox 19+ */
  input:-moz-placeholder { text-overflow: ellipsis; }

  .formBloc2 .formBloc2_6 input {
    width: 98%;;
  }

  .formBloc2 .formBloc2_7 textarea {
    width: 98%;;
  }

  /* END DEMANDE ETABLISSEMENT SECTION 1 ------------------------------------------------------------------------------------------*/

  /* VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  .visualisationDemandeBloc1 .titleForm,
  .inscriptionStep1 .titleForm,
  .inscriptionStep2 .titleForm {
    padding: 2rem;
  }

  /* END VISUALISATION DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/

  /* END DEMANDE ETABLISSEMENT ------------------------------------------------------------------------------------------*/


  /* LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

  .listeDemandeBloc1 .filtres {
    margin: 1rem 0 4rem 0;
  }

  /* END LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/


  /* LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/

  .listeDemandeBloc1 .filtres > p {
    width: 35%;
    margin-bottom: 0;
  }

  /* END LISTE DES DEMANDES ------------------------------------------------------------------------------------------*/


  /* ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/

  .alerteDispoBloc1 .inputInfo,
  .infoDispoBloc1 .inputInfo {
    padding-right: 9rem;
  }

  /* END ALERTE ET INFOS DISPO ------------------------------------------------------------------------------------------*/


  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

  .resultatRecherche .titleResultat p {
    font-size: 1.4rem;
  }

  .resultatRecherche .titleResultat {
    padding: 1rem 2rem;
  }

  .sidebar .bouton a {
    font-size: 1.4rem;
  }

  .lienForm {
    padding: 3rem 2rem 1rem 2rem;
  }

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

}

@media screen and (min-width : 1440px) {

  h1, h2, h3 {
    font-size: 2.8rem;
  }

  /* HEADER ------------------------------------------------------------------------------------------*/

  .banniere .accroche{
    font-size:4.5rem;
  }

  /* INFOS ------------------------------------------------------------------------------------------*/

  .header ul.infosHeader > li:not(:last-of-type):after {
    padding-right: 2rem;
  }

  .header ul.infosHeader > li:not(:first-of-type) {
    margin-left: 2rem;
  }

  /* END INFOS ------------------------------------------------------------------------------------------*/

  /* END HEADER ------------------------------------------------------------------------------------------*/


  /* HOME ------------------------------------------------------------------------------------------*/

  /* SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

  .homeBloc5 .slider i {
    font-size: 6rem;
  }

  .homeBloc5 .slider i:first-of-type {
    left: -4rem;
  }

  .homeBloc5 .slider i:last-of-type {
    right: -4rem;
  }

  /* END SECTION HOMEBLOC5 ------------------------------------------------------------------------------------------*/

  /* END HOME ------------------------------------------------------------------------------------------*/

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

  .resultatRecherche .titleResultat {
    padding: 1rem 4rem;
  }

  .singleResultat {
    padding: 0 4rem;
  }

  .singleResultat .iconeResultat img:first-of-type {
    width: 20%;
  }

  .singleResultat .iconeResultat img:last-of-type {
    max-width: 5%;
  }

  .lienForm .singleLienForm .contentLienForm p {
    font-size: 1.4rem;
  }

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

}

@media screen and (min-width : 1917px) {

  /* HEADER ------------------------------------------------------------------------------------------*/

  .banniere .form form{
    margin: 6rem auto;
  }

  /* INFOS ------------------------------------------------------------------------------------------*/

  .header ul.infosHeader > li:not(:last-of-type):after {
    padding-right: 3rem;
  }

  /* END INFOS ------------------------------------------------------------------------------------------*/

  /* END HEADER ------------------------------------------------------------------------------------------*/


  /* HOME ------------------------------------------------------------------------------------------*/

  /* SECTION BANNIERE ------------------------------------------------------------------------------------------*/

  .banniere .contentBanniere .titreBanniere:first-of-type {
    margin-right: 3rem;
  }

  .banniere .contentBanniere .titreBanniere:last-of-type,
  .homeBloc1 .annonce .contentAnnonce {
    margin-left: 3rem;
  }

  .banniere .contentBanniere img {
    width: 20%;
  }

  .banniere .contentBanniere p {
    font-size: 2rem;
  }

  /* END BANNIERE ------------------------------------------------------------------------------------------*/

  /* END HOME ------------------------------------------------------------------------------------------*/

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

  .resultatRecherche .titleResultat {
    padding: 1rem 6rem;
  }

  .singleResultat {
    padding: 0 6rem;
  }

  .singleResultat .iconeResultat img:first-of-type {
    width: 15%;
  }

  .singleResultat .iconeResultat img:last-of-type {
    max-width: 4%;
  }

  /* RESULTAT RECHERCHE ------------------------------------------------------------------------------------------*/

}



.encadreForm.seconde, .encadreForm.third{
  display:none;
}

.resultprint{
  display:none
}

.listeannonceAdmin{
  min-height:50rem!important;
}

.no_result{
  text-align: center;
  padding:10px;
  color: #c4574e;
}

.no_result p{
  font-weight: 900;
  font-size: 1.8rem;
}

.list_result {
  width: calc(100% - 24px);
  padding: 5px;
  border: 1px solid black;
  margin-top: -2px;
  position: absolute;
  top: 62px;
  background: white;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
}

.inputQui select{
  width: 35%;
  margin: 0 20px 0px 0px;
}

.demandeEtablissementBloc1 .inputResidence .btnRadio:first-child {
  display: none;
}

.pages{
  text-align: center;
  margin-bottom: 1.5em;
}

.btn-page{
  cursor: pointer;
  display: inline-block;
  border: 1px solid #c4574e;
  color: #337ab7;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.btn-page.active{
  background: #c4574e;
}

.btn-page.active a{
  color: white;
}

.btn-page:hover{
  background: #c4574e;
}

.btn-page a{
  color: #c4574e;
}

.btn-page a:hover{
  text-decoration: none;
}

.btn-page:hover a{
  color: white;
}


#appbundle_annonce_prix>label{
  margin-right: 2rem;
}

#appbundle_annonce_prix>input{
  width:auto;
  margin-right: .5rem;
}

.subTitleResultat img{
  display: inline;
  margin-right:1rem;
  max-width:2rem;
}
.annuler{
  margin:0;
}
.annuler a{
  background-color: #b7bccf;
}

.annuler a:hover{
  background-color: #464e6d;
}
.annuler button {
  margin: 0;
}

.visualisationInfo.description{
  display: block;
}

.visualisationInfo.description img{
  float:left;
}

.visualisationInfo.description p:nth-child(2){
  display: inline-block;
}

.visualisationInfo.description p:nth-child(3){
  margin-top:2rem;
}

.visualisationDemandeBloc1 .visualisationInfo{
  min-height:4rem;
}
.visualisationInfo i, .visualisationInfo img{
  margin-right:0.5rem;
}

.alert-success{
  text-align: center;
  display: table;
  margin: 2rem auto 0;
}

#listeDemande .listedemandeFamille{
  min-height: 45rem;
}


.titleForm p{
  cursor: pointer;
}

.inputSpecificite input{
  width:auto;

  margin-right:2rem;
}

.bouton.voir.second{
  left:inherit!important;
  right:3rem;

}

.bouton.visualisation a{
  margin-left: 1rem;
}

.banniere, .banniere img{
  width:100%
}

.homeBlockdispo > div:first-child{
  color: white;
  background: #464e6d;
  margin-bottom: 0rem;
}
.homeBlockdispo > div{
  color: white;
  background-color: #858fb6;
  padding:2rem 0;
  margin-bottom: 5rem;
}

.homeBlockdispo h2{
  color:white;
  text-align: left;
}

.homeBlockdispo h2:after{
  display:none;
}

.homeBlockdispo img{
  margin: 0 auto;
  max-height: 23rem;
}

.homeBlockdispo .infoDispo img{
  padding-top:3rem;
}

.homeBlockdispo .infoDispo p{
  font-size: 2rem;
}

.homeBlockdispo .bouton a{
  font-size: 2rem;
}

.homeBlocEtablissement{
  margin:3rem 0;
}

.homeBlocEtablissement .listeEtablissement > div h3{
  font-size: 1.4rem;
}

.homeBlocEtablissement .listeEtablissement p{
  text-align: center;
}

.homeBlocEtablissement .listeEtablissement img{
  margin:0 auto;
}

.homeBlocEtablissement .listeEtablissement > div h3:after{
  display: none;
}

.homeBlocEtablissement .listeEtablissement > div{
  min-height: 30rem;
}

#appbundle_etablissement_informationHebergement label{
  width:95%;
}

#appbundle_etablissement_informationHebergement input{
  height: 18px;
  width:5%;
  margin-right: 0;
}

.listBoutonEtablissement{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3rem;
}

.banniere-last{
  display: inherit;
}

#listeDemande .listebutton{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#listeDemande .listebutton .bouton{
  margin:2rem 0;
}

#listeDemande .listebutton > div:first-child {

}

#listeDemande .listebutton > div:nth-child(2) {

}

.contentFonctionnement .bouton a{
  font-size: 1.2rem;
}

.contentFonctionnement .outils img.img-responsive {

}

.textOu{
  text-align: center!important;
}



@media screen and (max-width: 640px) {

  .list_result {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
    border-top: none;
    margin-top: -2rem;
    position: absolute;
    top: 62px;
    background: white;
    z-index: 2;
  }

  .list_result a{
    font-size: 2rem;
    display: table;
    z-index:20;
    padding:1rem;
    width:100%;
  }
}

.green-dot, .orange-dot {
  height: 11px;
  width: 11px;
  background-color: #2C2;
  border: 2px solid #191;
  border-radius: 50%;
  display: inline-block;
}
.orange-dot {
  background-color: #F99600;
  border: 2px solid #F70;
}
#nameSearch, #phoneSearch {
  width: 50%;
  margin: 20px 25%;
}

/* Justice rework */

.ImageGallery {
  border: 3px solid lightgrey;
  margin: 5px 0 15px 0;
}

/* Auto Suggest */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  height: 4rem;
  width: 100%;
  padding: 1rem;
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  font-size: 14px;
  border: 1px solid #aaa;
  border-radius: .5rem;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  opacity: 1;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  margin-top: 2px;
  list-style-type: none;
  overflow:scroll;
  height:300px;
  opacity: 1;
  background-color: whitesmoke;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #474E6C;
  color: white;
}

/* input département */

.rainbow{
  background-color: #464e6d;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 8px 16px;

}

.rainbow-1:hover{
  background-image: -webkit-gradient(linear, left top, right top, from(#c4574e), color-stop(49%, #c4574e), color-stop(80%, #c4574e), to(#464e6d));
  background-image: -o-linear-gradient(left, #c4574e 0%, #c4574e 49%, #c4574e 80%, #464e6d 100%);
  background-image: linear-gradient(90deg, #c4574e 0%, #c4574e 49%, #c4574e 80%, #464e6d 100%);
  -webkit-animation:slidebg 3s linear infinite;
  animation:slidebg 3s linear infinite;
}

@-webkit-keyframes slidebg {
  to {
    background-position:20vw;
  }
}

@keyframes slidebg {
  to {
    background-position:20vw;
  }
}
